@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'NotoSans';
  src: url('./assets/fonts/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Black';
  src: url('./assets/fonts/NotoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('./assets/fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-ExtraBold';
  src: url('./assets/fonts/NotoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-ExtraCondensed-Black';
  src: url('./assets/fonts/NotoSans_ExtraCondensed-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: condensed;
}

html,
body,
#root {
  height: 100%;
}

.sidebar:before {
  content: '';
  position: absolute;
  width: 220px;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: white;
  border: none;
}

.Toastify__toast--success {
  background-color: #0066b1 !important;
}

.Toastify__toast--error {
  background-color: #d93400 !important;
}

/* 
----------------------------------------------------------
  Default page print style:
----------------------------------------------------------
*/
@media print {
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
  }

  @page {
    size: A4 portrait;
    margin-right: 0cm;
    margin-bottom: 0.85cm;
    margin-left: 0cm;
    margin-top: 0.85cm;
    padding: 0cm;
  }
}
/* 
----------------------------------------------------------
  Default page print style.
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Sidebar
----------------------------------------------------------
*/
.main-menu-item:hover .menu-item-icon {
  color: #00c8bb !important;
}

#sidebar-menu::-webkit-scrollbar {
  width: 5px;
}

#sidebar-menu::-webkit-scrollbar-track {
  padding: 5px 2px 5px 0px;
}

#sidebar-menu::-webkit-scrollbar-thumb {
  background-color: #00c8bb;
  border-radius: 20px;
}
/* 
----------------------------------------------------------
  Sidebar
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Main scrollbar
----------------------------------------------------------
*/
#scrollable-parent::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

#scrollable-parent::-webkit-scrollbar-track {
  background: #f5f5f5; /* color of the tracking area */
  padding: 5px 2px 5px 0px;
}

#scrollable-parent::-webkit-scrollbar-thumb {
  background-color: #00c8bb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
/* 
----------------------------------------------------------
  Main scrollbar
----------------------------------------------------------
*/
